.wrapper {
    background: var(--top-nav-primary);
    box-shadow: 0 1px 4px 0 rgba(41, 45, 55, 0.16);
    height: 6rem;
    display: grid;
    grid-template-columns: 18rem 1fr max-content;
    align-items: center;
  
    span {
      color: var(--white);
    }
  
    ul {
      a {
        height: 100%;
        text-decoration: none;
  
        li {
          display: grid;
          align-items: center;
          height: 100%;
          color: var(--white);
          text-transform: uppercase;
          padding: 0 0.7rem;
          font-size: 1.2rem;
          letter-spacing: 0px;
          text-align: center;
          width: max-content;
  
          &:hover {
            background: var(--primary);
            cursor: pointer;
          }
        }
  
        .active {
          background: var(--primary);
          cursor: pointer;
          font-weight: var(--font-semi-bold);
        }
      }
    }
  
    .logo {
      background: var(--top-nav-primary);
      display: grid;
      height: 6rem;
      align-items: center;
      padding: 0 2rem;
      fill: #fff!important
    }
  
    .menuItems {
      display: grid;
      align-items: center;
      height: 100%;
      justify-content: center;
  
      ul {
        display: grid;
        align-items: center;
        grid-auto-flow: column;
        justify-content: center;
        grid-gap: 3rem;
        padding: 0 0.7rem;
        margin: 0;
        height: 100%;
      }
    }
  
    .profile {
      display: grid;
      align-items: center;
      grid-auto-flow: column;
      justify-content: right;
      grid-gap: 2rem;
      padding: 0 2rem;
      cursor: pointer;
    }
  
    .userprofile {
      display: grid;
      align-items: center;
      grid-auto-flow: column;
      justify-content: right;
      grid-gap: 2rem;
      padding: 0 2rem;
      // cursor: pointer;
    }
  }
  
  .hide {
    opacity: 0;
  }
  
  .show {
    opacity: 1;
  }
  